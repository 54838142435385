import { API_HOST, TOKEN, USERNAME, ROL, SERVER_VERSION } from "../utiles/constantes";
import axios from "axios";

export async function loginApi(datosFormulario) {
    const url = `${API_HOST}/login`;
    const { email, password } = datosFormulario;

    return axios.post(url, null, {
        params: {
            email,
            password,
        },
    });
}

export function setTokenApi(token) {
    localStorage.setItem(TOKEN, token);
}

export function getTokenApi() {
    return localStorage.getItem(TOKEN);
}

export function borraTokenApi() {
    localStorage.removeItem(TOKEN);
}

export function setUsernameApi(username) {
    localStorage.setItem(USERNAME, username);
}

export function getUsernameApi() {
    return localStorage.getItem(USERNAME);
}

export function borraUsernameApi() {
    localStorage.removeItem(USERNAME);
}

export function setRolApi(rol) {
    localStorage.setItem(ROL, rol);
}

export function getRolApi() {
    return localStorage.getItem(ROL);
}

export function borraRolApi() {
    localStorage.removeItem(ROL);
}

export function isAdminApi() {
    return getRolApi() === "ADMIN";
}

export function setServerVersionApi(token) {
    localStorage.setItem(SERVER_VERSION, token);
}

export function getServerVersionApi() {
    return localStorage.getItem(SERVER_VERSION);
}

export function borraServerVersionApi() {
    localStorage.removeItem(SERVER_VERSION);
}
