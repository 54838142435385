import { React, useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { values, size } from "lodash";
import { Toast } from "primereact/toast";
import { isEmailValido } from "../utiles/validaciones";
import { loginApi, setTokenApi, setUsernameApi, setRolApi, setServerVersionApi } from "../api/autenticacion";

export const Login = () => {
    const [datosFormulario, setDatosFormulario] = useState(valorInicialForm());
    const toast = useRef();
    const history = useHistory();

    function irAlInicio() {
        history.push("/");
    }

    const onSubmit = (e) => {
        e.preventDefault();

        let contajeDeValidos = 0;
        values(datosFormulario).some((valorDelCampo) => {
            valorDelCampo && contajeDeValidos++;
            return null;
        });

        if (size(datosFormulario) !== contajeDeValidos) {
            toast.current.show({ severity: "warn", summary: "Datos no válidos", detail: "Usuario o contraseña no rellenados" });
            return;
        }
        if (!isEmailValido(datosFormulario.email)) {
            toast.current.show({ severity: "warn", summary: "Datos no válidos", detail: "No es una dirección de email válida" });
            return;
        }

        console.log("Datos del formulario correctos.");

        loginApi(datosFormulario)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    //console.log(">>> LOGIN OK!!! - response.data.succes: " + respuesta.data.success);
                    toast.current.show({ severity: "success", summary: "OK", detail: "Credenciales correctas" });
                    const token = respuesta.data.items[0].token;
                    const nombre = respuesta.data.items[0].gestor.nombre;
                    const apellidos = respuesta.data.items[0].gestor.apellidos;
                    const rol = respuesta.data.items[0].gestor.rol;
                    setTokenApi(token);
                    setUsernameApi(nombre + " " + apellidos);
                    setRolApi(rol);
                    setServerVersionApi(respuesta.data.version);
                    //console.log(">>> Guardanto Token: " + token + " ,Nombre: " + nombre + " ,Rol: " + rol + " ,isAdmin: " + isAdminApi());
                    irAlInicio();
                } else {
                    console.log(respuesta.data.success);
                    toast.current.show({ severity: "error", summary: "Error", detail: "Usuario y contraseña no váildos" });
                }
            })
            .catch((response) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    };

    const onChange = (e) => {
        setDatosFormulario({ ...datosFormulario, [e.target.name]: e.target.value });
    };

    return (
        <>
            <Toast ref={toast} position="top-left"></Toast>
            <div className="login-body">
                <div className="login-wrapper">
                    <div className="login-panel">
                        <img src="assets/layout/images/logo-dark.svg" className="logo" alt="diamond-layout" />
                        <form onSubmit={onSubmit} onChange={onChange}>
                            <div className="login-form">
                                <h2>Acceso</h2>
                                <p>Debe disponer de una cuenta de usuario</p>
                                <InputText id="email" name="email" placeholder="Email" />
                                <Password id="password" name="password" placeholder="Password" feedback={false} />
                                <Button label="ACCEDER" type="submit"></Button>
                                {/*<Button label="ACCEDER" type="button" onClick={irLogin}></Button> */}
                            </div>
                        </form>
                        <p>
                            &copy; EuroSegur 2021{" · "}
                            <a href="https://www.eurosegur.com" rel="noreferrer" target="_blank">
                                www.eurosegur.com
                            </a>
                        </p>
                    </div>
                    <div className="login-image">
                        <div className="login-image-content">
                            <h1>EuroSegur PGI</h1>
                            <h3>
                                Acceso disponible solo para personal de EuroSegur
                                <br />
                                Se registrarán todos los intentos de acceso.
                            </h3>
                        </div>
                        <div className="image-footer">
                            <p>Puede consultar información pública en nuestras redes sociales.</p>
                            <div className="icons">
                                <i className="pi pi-facebook"></i>
                                <i className="pi pi-twitter"></i>
                                <i className="pi pi-youtube"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

function valorInicialForm() {
    return {
        email: "",
        password: "",
    };
}
