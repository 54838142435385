import React from "react";
import moment from "moment";
import { ActivoTexto } from "./ActivoTexto";
import { Card } from "primereact/card";

export function IncidenciaTexto(props) {
    const { incidencia } = props;

    let subTitle = (
        <>
            <i className="pi pi-user-plus"></i> + {incidencia.gestor.nombre} {incidencia.gestor.apellidos}
        </>
    );

    return (
        <Card style={{ margin: "10px" }} title={moment(incidencia.fecha).format("DD/MM/YYYY HH:mm")} subTitle={subTitle}>
            <p>
                Origen: <strong>{incidencia.origen}</strong>
            </p>
            <p>
                Motivo: <strong>{incidencia.motivo}</strong>
            </p>
            {incidencia.detalles && (
                <p>
                    Detalles: <strong>{incidencia.detalles}</strong>
                </p>
            )}
            {incidencia.solucion && (
                <p>
                    Solución: <strong>{incidencia.solucion}</strong>
                </p>
            )}
            <ActivoTexto activo={incidencia.cerrado} labelTrue="Cerrada" labelFalse="Abierta" /> <ActivoTexto activo={incidencia.cerradoCalidad} labelTrue="Cerrada por Calidad" labelFalse="Abierta por Calidad" />
            <div style={{ marginTop: "15px" }}>{incidencia.aviso}</div>
        </Card>
    );
}
