import { Button } from "primereact/button";
import { Chart } from "primereact/chart";
import { Menu } from "primereact/menu";
import { useEffect, useState, useRef } from "react";
import { getGeneralApi } from "../api/general";
import { Toast } from "primereact/toast";

const ordersChart = {
    labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    datasets: [
        {
            label: "Pedidos en fabricación",
            data: [2, 7, 20, 9, 16, 9, 5, 0, 0, 0, 0, 0],
            backgroundColor: ["rgba(100, 181, 246, 0.2)"],
            borderColor: ["#64B5F6"],
            borderWidth: 3,
            fill: true,
        },
    ],
};

const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
};

const ordersChartOptions = {
    legend: {
        display: true,
    },
    responsive: true,
    hover: {
        mode: "index",
    },
};

const revenueChart = {
    labels: ["Ventas", "Fabricación", "Oficina Técnica", "Expediciones"],
    datasets: [
        {
            data: [10, 10, 10, 10],
            backgroundColor: ["#64B5F6", "#7986CB", "#4DB6AC", "#004fAC"],
        },
    ],
};

const dataSetTemplate = [
    [2, 7, 20, 9, 16, 9, 5, 0, 0, 0, 0, 0],
    [2, 4, 9, 20, 16, 12, 20, 0, 0, 0, 0, 0],
    [2, 17, 7, 15, 4, 20, 8, 0, 0, 0, 0, 0],
    [2, 2, 20, 4, 17, 16, 20, 0, 0, 0, 0, 0],
];

export const Inicio = () => {
    const [datos, setDatos] = useState();
    const [datosDptos, setDatosDptos] = useState(revenueChart);
    const [dataSet, setDataSet] = useState(dataSetTemplate);
    const toast = useRef(null);

    useEffect(() => {
        getGeneralApi("estadisticaGeneral")
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setDatos(respuesta.data.items[0]);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error en lista de datos" });
                }
            })
            .catch(() => {
                toast?.current?.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });

        getGeneralApi("estadisticaDptos")
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setDatosDptos(respuesta.data.items[0]);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error en lista de datos" });
                }
            })
            .catch(() => {
                toast?.current?.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });

        getGeneralApi("estadisticaPedidos")
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setDataSet(respuesta.data.items[0].dataSet);
                    ordersChart.datasets[0].data = respuesta.data.items[0].dataSet[0];
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error en lista de datos" });
                }
            })
            .catch(() => {
                toast?.current?.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }, []);

    const items = [
        {
            label: "Shipments",
            items: [
                { label: "Tracker", icon: "pi pi-compass" },
                { label: "Map", icon: "pi pi-map-marker" },
                { label: "Manage", icon: "pi pi-pencil" },
            ],
        },
    ];

    const menuRef = useRef(null);
    const chartRef = useRef(null);

    const changeDataset = (event) => {
        ordersChart.datasets[0].data = dataSet[parseInt(event.currentTarget.getAttribute("data-index"))];
        ordersChart.datasets[0].label = event.currentTarget.getAttribute("data-label");
        ordersChart.datasets[0].borderColor = event.currentTarget.getAttribute("data-stroke");
        ordersChart.datasets[0].backgroundColor = event.currentTarget.getAttribute("data-fill");
    };

    const menuToggle = (event) => {
        menuRef.current.toggle(event);
    };

    const refreshDataset = (event) => {
        chartRef.current.refresh(event);
    };

    const onOrderTabClick = (event) => {
        changeDataset(event);
        refreshDataset(event);
    };

    return (
        <>
            <Toast ref={toast} position="top-right"></Toast>
            <div className="layout-dashboard">
                <div className="p-grid">
                    <div className="p-col-12 p-md-6 p-xl-3">
                        <div className="card no-gutter widget-overview-box widget-overview-box-1">
                            <span className="overview-icon">
                                <i className="pi pi-shopping-cart"></i>
                            </span>
                            <span className="overview-title">Pedidos</span>
                            <div className="p-grid overview-detail">
                                <div className="p-col-4">
                                    <div className="overview-number">{datos?.totalPedidos}</div>
                                    <div className="overview-subtext">Total</div>
                                </div>
                                <div className="p-col-4">
                                    <div className="overview-number">{datos?.totalPedidosRecibidos}</div>
                                    <div className="overview-subtext">Recibidos</div>
                                </div>
                                <div className="p-col-4">
                                    <div className="overview-number">{datos?.totalPedidosEnviados}</div>
                                    <div className="overview-subtext">Enviados</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-6 p-xl-3">
                        <div className="card no-gutter widget-overview-box widget-overview-box-2">
                            <span className="overview-icon">
                                <i className="pi pi-dollar"></i>
                            </span>
                            <span className="overview-title">Presupuestos</span>
                            <div className="p-grid overview-detail">
                                <div className="p-col-4">
                                    <div className="overview-number">{datos?.totalPptos}</div>
                                    <div className="overview-subtext">Total</div>
                                </div>
                                <div className="p-col-4">
                                    <div className="overview-number">{datos?.totalPptosPedido}</div>
                                    <div className="overview-subtext">A Pedido</div>
                                </div>
                                <div className="p-col-4">
                                    <div className="overview-number">{datos?.totalPpptosNormal}</div>
                                    <div className="overview-subtext">Sin Pedido</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-6 p-xl-3">
                        <div className="card no-gutter widget-overview-box widget-overview-box-3">
                            <span className="overview-icon">
                                <i className="pi pi-users"></i>
                            </span>
                            <span className="overview-title">Clientes</span>
                            <div className="p-grid overview-detail">
                                <div className="p-col-6">
                                    <div className="overview-number">{datos?.totalUsuariosActivos}</div>
                                    <div className="overview-subtext">Activos</div>
                                </div>
                                <div className="p-col-6">
                                    <div className="overview-number">{datos?.totalUsuarios}</div>
                                    <div className="overview-subtext">Total</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-6 p-xl-3">
                        <div className="card no-gutter widget-overview-box widget-overview-box-4">
                            <span className="overview-icon">
                                <i className="pi pi-comment"></i>
                            </span>
                            <span className="overview-title">Gestores</span>
                            <div className="p-grid overview-detail">
                                <div className="p-col-6">
                                    <div className="overview-number">{datos?.totalGestoresActivos}</div>
                                    <div className="overview-subtext">Activos</div>
                                </div>
                                <div className="p-col-6">
                                    <div className="overview-number">{datos?.totalGestores}</div>
                                    <div className="overview-subtext">Total</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-col-12 p-xl-6">
                        <div className="card no-gutter orders">
                            <div className="card-header">
                                <h4>Pedidos año actual</h4>
                                <Menu id="orders-button" popup model={items} ref={menuRef} appendTo={document.body}></Menu>
                                <Button type="button" icon="pi pi-search" className="p-button-text p-button-secondary" onClick={menuToggle} aria-controls="orders-button" aria-haspopup="true"></Button>
                            </div>

                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div id="order-tabs-container" className="p-grid order-tabs">
                                        <div className="order-tab order-tab-new p-col-6 p-md-3" onClick={onOrderTabClick} data-label="Pedidos en fabricación" data-index="0" data-stroke="#BBDEFB" data-fill="rgba(100, 181, 246, 0.2)">
                                            <i className="pi pi-check-circle"></i>
                                            <span className="order-label">FABRICACIÓN</span>
                                            <i className="stat-detail-icon icon-arrow-right-circle"></i>
                                            <img src="assets/demo/images/dashboard/graph-new.svg" alt="order" />
                                        </div>
                                        <div className="order-tab order-tab-completed p-col-6 p-md-3" onClick={onOrderTabClick} data-label="Pedidos cancelados" data-index="1" data-stroke="#C5CAE9" data-fill="rgba(121, 134, 203, 0.2)">
                                            <i className="pi pi-times-circle"></i>
                                            <span className="order-label">CANCELADO</span>
                                            <i className="stat-detail-icon icon-arrow-right-circle"></i>
                                            <img src="assets/demo/images/dashboard/graph-completed.svg" alt="order" />
                                        </div>
                                        <div className="order-tab order-tab-refunded p-col-6 p-md-3" onClick={onOrderTabClick} data-label="Pedidos paralizados" data-index="2" data-stroke="#B2DFDB" data-fill="rgba(224, 242, 241, 0.5)">
                                            <i className="pi pi-minus-circle"></i>
                                            <span className="order-label">PARALIZADO</span>
                                            <i className="stat-detail-icon icon-arrow-right-circle"></i>
                                            <img src="assets/demo/images/dashboard/graph-refunded.svg" alt="order" />
                                        </div>
                                        <div className="order-tab order-tab-cancelled p-col-6 p-md-3" onClick={onOrderTabClick} data-label="Pedidos preconfirmados" data-index="3" data-stroke="#B2EBF2" data-fill="rgba(224, 247, 250, 0.5)">
                                            <i className="pi pi-exclamation-circle"></i>
                                            <span className="order-label">PRECONFIRMADO</span>
                                            <i className="stat-detail-icon icon-arrow-right-circle"></i>
                                            <img src="assets/demo/images/dashboard/graph-cancelled.svg" alt="order" />
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12">
                                    <div className="overview-chart">
                                        <Chart ref={chartRef} type="line" data={ordersChart} options={ordersChartOptions} id="order-chart"></Chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-col-12 p-xl-6">
                        <div className="card revenue">
                            <h4>Departamentos</h4>
                            <p>Carga de trabajo por departamento</p>
                            <div className="revenue-chart-container">
                                <Chart type="pie" id="revenue-chart" data={datosDptos} options={pieChartOptions} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
