export const API_HOST = `${process.env.REACT_APP_PGI_API_HOST}`;
export const TOKEN = "pgi_token";
export const USERNAME = "pgi_username";
export const ROL = "pgi_rol";
export const SERVER_VERSION = "pgi_server_version";
export const CALENDAR_LOCALE_ES = {
    firstDayOfWeek: 1,
    dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
    monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
    monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
    today: "Hoy",
    clear: "Borrar",
};
