import React from "react";

export function RolTexto(props) {
    const { children } = props;

    let estilo;
    if (children === "ADMIN") {
        estilo = "success";
    } else {
        estilo = "warn";
    }

    return (
        <div aria-live="polite" className={`p-inline-message p-component p-inline-message-${estilo}`} role="alert">
            <span className="p-inline-message-icon pi pi-times-circle"></span>
            <span className="p-inline-message-text">{children}</span>
        </div>
    );
}
