import { API_HOST } from "../utiles/constantes";
import { getTokenApi } from "./autenticacion";
import axios from "axios";

export async function postGeneralApi(uri, datosFormulario, id) {
    let url = `${API_HOST}/${uri}`;
    if (id) {
        url = `${API_HOST}/${uri}/${id}`;
    }
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getTokenApi()}`,
    };
    return axios.post(url, datosFormulario, { headers });
}

export async function getGeneralApi(uri, id) {
    let url = `${API_HOST}/${uri}`;
    if (id) {
        url = `${API_HOST}/${uri}/${id}`;
    }
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getTokenApi()}`,
    };
    return axios.get(url, { headers });
}
