import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import { postGeneralApi } from "../api/general";
import { size, map } from "lodash";
import { BloqueLlegadaMaterial } from "./BloqueLlegadaMaterial";

export function ProcRegFechaPrev(props) {
    const { pedido, callBack } = props;
    const overlayPanel = useRef(null);
    const toast = useRef();
    const labelGeneral = "Proceso: " + pedido?.flujo?.nombre;
    const [reload, setReload] = useState(Date.now());

    const onSubmit = (e) => {
        e.preventDefault();
        const datosFormularioEnvio = {
            llegadaMaterial: [],
        };
        postGeneralApi("procRegFechaPrev", datosFormularioEnvio, pedido.id)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    toast.current.show({ severity: "success", summary: "OK", detail: "Envío correcto: " + respuesta?.data?.mensaje });
                    overlayPanel.current.hide();
                    setTimeout(() => {
                        callBack(Date.now());
                    }, 1000);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error en proceso: " + respuesta?.data?.mensaje });
                    overlayPanel.current.hide();
                }
            })
            .catch((response) => {
                console.log("Error API[" + response + "]");
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    };

    useEffect(() => {
        callBack(reload);
    }, [callBack, reload]);

    return (
        <>
            <Toast ref={toast} />
            <Button style={{ width: "100%" }} icon="pi pi-check" className="p-button p-component p-button-outlined p-button-secondary" type="button" label={labelGeneral} onClick={(e) => overlayPanel.current.toggle(e)} />
            <OverlayPanel showCloseIcon dismissable ref={overlayPanel}>
                <form onSubmit={onSubmit}>
                    <div className="card p-fluid">
                        <h5>{labelGeneral}</h5>
                        <p>{pedido?.flujo?.descripcion}</p>
                        <div className="p-formgrid p-grid">
                            {size(pedido?.llegadaMaterial) > 0 && map(pedido?.llegadaMaterial, (lm, index) => <BloqueLlegadaMaterial llamada={setReload} key={lm.id} llegadaMaterial={lm} pedidoId={pedido?.id} nuevo={false} />)}
                            <BloqueLlegadaMaterial llamada={setReload} llegadaMaterial={[]} pedidoId={pedido?.id} nuevo={true} />
                        </div>
                        <div className="p-field p-col-3">
                            <label htmlFor="actuar" style={{ visibility: "hidden" }}>
                                Actualizar
                            </label>
                            <Button label="Actuar" type="submit"></Button>
                        </div>
                    </div>
                </form>
            </OverlayPanel>
        </>
    );
}
