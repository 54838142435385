import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { addLocale } from "primereact/api";
import { CALENDAR_LOCALE_ES } from "../utiles/constantes";
import { postGeneralApi } from "../api/general";
import { Toast } from "primereact/toast";

export function BloqueLlegadaMaterial(props) {
    const { llamada, llegadaMaterial, pedidoId, nuevo } = props;
    const [fechaCal, setFechaCal] = useState(llegadaMaterial?.fecha);
    const [proveedor, setProveedor] = useState(llegadaMaterial?.proveedor);
    const [material, setMaterial] = useState(llegadaMaterial?.material);
    const toast = useRef();

    addLocale("es", CALENDAR_LOCALE_ES);

    const inserta = (e) => {
        const datosFormularioEnvio = {
            fecha: fechaCal,
            proveedor: proveedor,
            material: material,
        };
        postGeneralApi("updateDatosPedidoCreaLlegadaMaterial", datosFormularioEnvio, pedidoId)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    toast.current.show({ severity: "success", summary: "OK", detail: "Fecha insertada" });
                    llamada(Date.now());
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: `${respuesta?.data?.mensaje}` });
                }
            })
            .catch((response) => {
                console.log("Error API[" + response + "]");
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    };

    const actualiza = (e) => {
        const datosFormularioEnvio = {
            id: llegadaMaterial?.id,
            fecha: fechaCal,
            proveedor: proveedor,
            material: material,
        };
        postGeneralApi("updateDatosPedidoActualizaLlegadaMaterial", datosFormularioEnvio, pedidoId)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    toast.current.show({ severity: "success", summary: "OK", detail: "Fecha actualizada" });
                    llamada(Date.now());
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: `${respuesta?.data?.mensaje}` });
                }
            })
            .catch((response) => {
                console.log("Error API[" + response + "]");
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    };

    const borra = (e) => {
        const datosFormularioEnvio = {
            largo: llegadaMaterial?.id,
        };
        postGeneralApi("updateDatosPedidoBorraLlegadaMaterial", datosFormularioEnvio, pedidoId)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    toast.current.show({ severity: "success", summary: "OK", detail: "Fecha eliminada" });
                    llamada(Date.now());
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error borrando datos" });
                }
            })
            .catch((response) => {
                console.log("Error API[" + response + "]");
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    };

    return (
        <>
            <Toast ref={toast} />
            <div>
                {nuevo && <h5 style={{ marginTop: "20px" }}>Nueva Fecha</h5>}
                <div className="p-formgrid p-grid">
                    <div className="p-field p-col-3">
                        <label htmlFor="fecha">Fecha prevista</label>
                        <Calendar showButtonBar={true} locale="en" dateFormat="dd/mm/yy" id="fecha" showIcon value={new Date(fechaCal)} onChange={(e) => setFechaCal(e.value)}></Calendar>
                    </div>
                    <div className="p-field p-col-3">
                        <label htmlFor="proveedor">Proveedor</label>
                        <InputText id="proveedor" defaultValue={proveedor} placeholder="Insertar Proveedor" onChange={(e) => setProveedor(e.target.value)} />
                    </div>
                    <div className="p-field p-col-3">
                        <label htmlFor="material">Material</label>
                        <InputText id="material" defaultValue={material} placeholder="Insertar Material" onChange={(e) => setMaterial(e.target.value)} />
                    </div>

                    <div className="p-field p-col-1">
                        <label htmlFor="borrar" style={{ visibility: "hidden" }}>
                            Inserta/Actualizar
                        </label>
                        {nuevo ? <Button id="borrar" type="button" icon="pi pi-plus-circle" onClick={inserta}></Button> : <Button id="borrar" type="button" icon="pi pi-refresh" onClick={actualiza}></Button>}
                    </div>
                    <div className="p-field p-col-1">
                        <label htmlFor="borrar" style={{ visibility: "hidden" }}>
                            Borrar
                        </label>
                        <Button id="borrar" disabled={nuevo} type="button" icon="pi pi-trash" className="p-button-danger" onClick={borra}></Button>
                    </div>
                </div>
            </div>
        </>
    );
}
