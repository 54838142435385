import React from "react";
import moment from "moment";
import { ActivoTexto } from "../componentes/ActivoTexto";
import { Card } from "primereact/card";

export function AvisoTexto(props) {
    const { aviso } = props;

    let subTitle = (
        <>
            <i className="pi pi-user-plus"></i> + {aviso.gestor.nombre} {aviso.gestor.apellidos}
        </>
    );

    return (
        <Card style={{ margin: "10px" }} title={moment(aviso.fecha).format("DD/MM/YYYY HH:mm")} subTitle={subTitle}>
            <ActivoTexto activo={aviso.activo} labelTrue="Pendiente" labelFalse="Resuelto" />
            <div style={{ marginTop: "15px" }}>{aviso.aviso}</div>
        </Card>
    );
}
