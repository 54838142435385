import React from "react";
import { Button } from "primereact/button";

export function ActivoTexto(props) {
    const { activo, label, labelTrue, labelFalse, className } = props;

    let txtLabelTrue = "Activo";
    let txtLabelFalse = "No Activo";
    if (labelTrue) {
        txtLabelTrue = labelTrue;
    }
    if (labelFalse) {
        txtLabelFalse = labelFalse;
    }
    let classNameFinal = "p-button-secondary " + className;
    let icon = "pi pi-times";
    let labelFinal = txtLabelFalse;
    if (activo) {
        classNameFinal = "p-button-success " + className;
        icon = "pi pi-check";
        labelFinal = txtLabelTrue;
    }
    if (label) {
        labelFinal = label + labelFinal;
    }

    return <Button disabled className={classNameFinal} label={labelFinal} icon={icon}></Button>;
}
