import { clasificacion } from "../api/varios";

export function ClasificacionTexto(props) {
    const { id } = props;

    return (
        <span className="table-demo">
            <span className={`customer-badge status-${id}`}>{clasificacion[clasificacion[id].value].label}</span>
        </span>
    );
}
