import { ProcVbPedNoAcep } from "../componentes/ProcVbPedNoAcep";
import { ProcPendClasPed } from "../componentes/ProcPendClasPed";
import { ProcAsigGestor } from "../componentes/ProcAsigGestor";
import { ProcPendCondFirma } from "../componentes/ProcPendCondFirma";
import { ProcReqEstudioProy } from "../componentes/ProcReqEstudioProy";
import { ProcPlanosSecuencia } from "../componentes/ProcPlanosSecuencia";
import { ProcReqMatRef } from "../componentes/ProcReqMatRef";
import { ProcRegFechaPrev } from "../componentes/ProcRegFechaPrev";
import { ProcFechaFabEst } from "../componentes/ProcFechaFabEst";
import { ProcVbBdOk } from "../componentes/ProcVbBdOk";

export function SelectorProcesos(props) {
    const { param, pedido, callBack } = props;

    let procReturn = "";
    switch (param) {
        case "VB_PED_NO_ACEP":
            procReturn = <ProcVbPedNoAcep pedido={pedido} callBack={callBack} />;
            break;
        case "PEND_CLAS_PED":
            procReturn = <ProcPendClasPed pedido={pedido} callBack={callBack} />;
            break;
        case "ASIG_GESTOR":
            procReturn = <ProcAsigGestor pedido={pedido} callBack={callBack} />;
            break;
        case "PEND_COND_FIRMA":
            procReturn = <ProcPendCondFirma pedido={pedido} callBack={callBack} />;
            break;
        case "REQ_ESTUDIO_PROY":
            procReturn = <ProcReqEstudioProy pedido={pedido} callBack={callBack} />;
            break;
        case "PEND_PLANOS":
            procReturn = <ProcPlanosSecuencia proceso="procPendPlanos" pedido={pedido} callBack={callBack} />;
            break;
        case "PEND_PLANOS_CONFIRM":
            procReturn = <ProcPlanosSecuencia proceso="procPendPlanosConfirm" pedido={pedido} callBack={callBack} />;
            break;
        case "OK_ENVIO_PLANOS":
            procReturn = <ProcPlanosSecuencia proceso="procOkEnvioPlanos" pedido={pedido} callBack={callBack} />;
            break;
        case "REQ_MAT_REF":
            procReturn = <ProcReqMatRef pedido={pedido} callBack={callBack} />;
            break;
        case "REG_FECHA_PREV":
            procReturn = <ProcRegFechaPrev pedido={pedido} callBack={callBack} />;
            break;
        case "FECHA_FAB_EST":
            procReturn = <ProcFechaFabEst pedido={pedido} callBack={callBack} />;
            break;
        case "VB_BD_OK":
            procReturn = <ProcVbBdOk pedido={pedido} callBack={callBack} />;
            break;

        default:
    }

    return <div className="p-md-9 p-sm-12">{procReturn}</div>;
}
