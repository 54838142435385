/*
 * Componente para los siguientes procesos: PEND_PLANOS, PEND_PLANOS_CONFIRM  y OK_ENVIO_PLANOS
 */
import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import { postGeneralApi } from "../api/general";
import { Dropdown } from "primereact/dropdown";
import { lineaProyectoPlanos } from "../api/varios";

export function ProcPlanosSecuencia(props) {
    const { pedido, callBack, proceso } = props;
    const overlayPanel = useRef(null);
    const toast = useRef();
    const [lineaProyectoPlanosDrop, setLineaProyectoPlanosDrop] = useState(pedido.lineaProyectoPlanos);
    const labelGeneral = "Proceso: " + pedido?.flujo?.nombre;

    const onSubmit = (e) => {
        e.preventDefault();
        const datosFormularioEnvio = {
            lineaProyectoPlanos: lineaProyectoPlanosDrop, //Hack para primeReact Dropdown
        };

        //console.log("/>> ProcPlanosSecuencia: " + proceso);

        postGeneralApi(proceso, datosFormularioEnvio, pedido.id)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    toast.current.show({ severity: "success", summary: "OK", detail: "Envío correcto: " + respuesta?.data?.mensaje });
                    overlayPanel.current.hide();
                    setTimeout(() => {
                        callBack(Date.now());
                    }, 1000);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error en proceso: " + respuesta?.data?.mensaje });
                    overlayPanel.current.hide();
                }
            })
            .catch((response) => {
                console.log("Error API[" + response + "]");
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    };

    return (
        <>
            <Toast ref={toast} />
            <Button style={{ width: "100%" }} icon="pi pi-check" className="p-button p-component p-button-outlined p-button-secondary" type="button" label={labelGeneral} onClick={(e) => overlayPanel.current.toggle(e)} />
            <OverlayPanel showCloseIcon dismissable ref={overlayPanel}>
                <form onSubmit={onSubmit}>
                    <div className="card p-fluid">
                        <h5>{labelGeneral}</h5>
                        <p>{pedido?.flujo?.descripcion}</p>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col-6">
                                <label htmlFor="lineaProyectoEstudioTecnico">Estado de los Planos</label>
                                <Dropdown name="lineaProyectoEstudioTecnico" value={lineaProyectoPlanosDrop} options={Array.from(lineaProyectoPlanos)} onChange={(e) => setLineaProyectoPlanosDrop(e.value)} />
                            </div>
                            <div className="p-field">
                                <label htmlFor="actuar" style={{ visibility: "hidden" }}>
                                    Actuar
                                </label>
                                <Button label="Actuar" type="submit"></Button>
                            </div>
                        </div>
                    </div>
                </form>
            </OverlayPanel>
        </>
    );
}
