import React, { useRef } from "react";
import classNames from "classnames";
import AppBreadcrumb from "./AppBreadcrumb";
import { useHistory } from "react-router-dom";
import { getUsernameApi, getRolApi, borraTokenApi, borraUsernameApi, borraRolApi, borraServerVersionApi } from "../api/autenticacion";
import { RolTexto } from "../componentes/RolTexto";
import { getGeneralApi } from "../api/general";
import { Toast } from "primereact/toast";

const AppTopbar = (props) => {
    const toast = useRef();
    const history = useHistory();
    const profileItemClassName = classNames("profile-item", { "active-menuitem fadeInDown": props.topbarUserMenuActive });

    const username = getUsernameApi();
    const rol = getRolApi();

    const cerrarSesion = () => {
        salirServidor();
        borraTokenApi();
        borraUsernameApi();
        borraRolApi();
        borraServerVersionApi();
        history.push("/login");
    };

    async function salirServidor() {
        await getGeneralApi("logout")
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    console.log("OK: " + respuesta?.data?.mensaje);
                } else {
                    console.log("Error al desconectarse del servidor");
                }
            })
            .catch(() => {
                console.log("Error en la petición al servidor");
            });
    }

    async function getRecargarPptos() {
        await getGeneralApi("recargarPptos")
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    toast.current.show({ severity: "success", summary: "OK", detail: "" + respuesta?.data?.mensaje });
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error obteniendo lista de departamentos" });
                }
            })
            .catch(() => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="layout-topbar">
                <div className="topbar-left">
                    <button type="button" className="menu-button p-link" onClick={props.onMenuButtonClick}>
                        <i className="pi pi-chevron-left"></i>
                    </button>
                    <span className="topbar-separator"></span>

                    <div className="layout-breadcrumb viewname" style={{ textTransform: "uppercase" }}>
                        <AppBreadcrumb routers={props.routers} />
                    </div>

                    <img id="logo-mobile" className="mobile-logo" src="assets/layout/images/logo-dark.svg" alt="diamond-layout" />
                </div>

                <div className="topbar-right">
                    <ul className="topbar-menu">
                        <li className="search-item">
                            <button type="button" className="p-link" onClick={props.onSearchClick}>
                                <i className="pi pi-search"></i>
                            </button>
                        </li>

                        <li className={profileItemClassName}>
                            <button type="button" className="p-link" onClick={props.onTopbarUserMenu}>
                                <img src="assets/demo/images/avatar/profile.png" alt="diamond-layout" className="profile-image" />
                                <span className="profile-name">{username}</span>
                            </button>

                            <ul className="profile-menu fade-in-up">
                                <li>
                                    <button type="button" className="p-link" onClick={() => history.push("/datosGestor")}>
                                        <i className="pi pi-user"></i>
                                        <span>Datos Gestor</span>
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className="p-link" onClick={() => history.push("/config")}>
                                        <i className="pi pi-cog"></i>
                                        <span>Configuración</span>
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className="p-link" onClick={() => getRecargarPptos()}>
                                        <i className="pi pi-refresh"></i>
                                        <span>Refrescar paso a pedido</span>
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className="p-link" onClick={cerrarSesion}>
                                        <i className="pi pi-power-off"></i>
                                        <span>Salir</span>
                                    </button>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <RolTexto>{rol}</RolTexto>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default AppTopbar;
