import React from "react";
import Moment from "react-moment";
import { EstadoTexto } from "./EstadoTexto";

export function TrazaTexto(props) {
    const { traza } = props;
    const paddingLeft = "15px";
    const paddingRight = "5px";

    return (
        <>
            <i>
                <Moment format="DD/MM/YYYY HH:mm">{traza.fecha}</Moment>
            </i>
            <i style={{ paddingLeft: paddingLeft, paddingRight: paddingRight }} className="pi pi-user-plus"></i>
            <strong>{traza.gestor.nombreCompleto}</strong>
            <i style={{ paddingLeft: paddingLeft, paddingRight: paddingRight }} className="pi pi-sign-in"></i>
            <i style={{ paddingLeft: paddingLeft, paddingRight: paddingRight }} className="pi pi-users"></i>
            {traza.desde.nombre}
            <i style={{ paddingLeft: paddingLeft, paddingRight: paddingRight }} className="pi pi-arrow-right"></i>
            <i style={{ paddingLeft: paddingLeft, paddingRight: paddingRight }} className="pi pi-users"></i>
            {traza.hacia.nombre}
            <i style={{ paddingLeft: paddingLeft, paddingRight: paddingRight }} className="pi pi-ellipsis-h"></i>
            <EstadoTexto style={{ paddingLeft: paddingLeft }} estado={traza.estado} />
        </>
    );
}
