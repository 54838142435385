import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import { postGeneralApi, getGeneralApi } from "../api/general";
import { Dropdown } from "primereact/dropdown";
import { clasificacion, lineaProyectoGeneral } from "../api/varios";

export function ProcPendClasPed(props) {
    const { pedido, callBack } = props;
    const overlayPanel = useRef(null);
    const toast = useRef();
    const [clasificacionDrop, setClasificacionDrop] = useState(pedido.clasificacion);
    const [datosTipologias, setDatosTipologias] = useState({});
    const [tipologiaDrop, setTipologiaDrop] = useState(pedido.tipologia.id);
    const [lineaProyectoEstudioTecnicoDrop, setLineaProyectoEstudioTecnicoDrop] = useState(pedido.lineaProyectoEstudioTecnico);
    const [lineaProyectoMaterialReferenciadoDrop, setLineaProyectoMaterialReferenciadoDrop] = useState(pedido.lineaProyectoMaterialReferenciado);
    const labelGeneral = "Proceso: " + pedido?.flujo?.nombre;

    async function getListaDatos() {
        await getGeneralApi("listaTipologias")
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setDatosTipologias(respuesta.data.items);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error obteniendo lista de tipologías" });
                }
            })
            .catch((response) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }

    useEffect(() => {
        getListaDatos();
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        const datosFormularioEnvio = {
            clasificacion: clasificacionDrop, //Hack para primeReact Dropdown
            tipologia: { id: tipologiaDrop }, //Hack para primeReact Dropdown
            lineaProyectoEstudioTecnico: lineaProyectoEstudioTecnicoDrop, //Hack para primeReact Dropdown
            lineaProyectoMaterialReferenciado: lineaProyectoMaterialReferenciadoDrop, //Hack para primeReact Dropdown
        };
        postGeneralApi("procPendClasPed", datosFormularioEnvio, pedido.id)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    toast.current.show({ severity: "success", summary: "OK", detail: "Envío correcto: " + respuesta?.data?.mensaje });
                    overlayPanel.current.hide();
                    setTimeout(() => {
                        callBack(Date.now());
                    }, 1000);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error en proceso: " + respuesta?.data?.mensaje });
                    overlayPanel.current.hide();
                }
            })
            .catch((response) => {
                console.log("Error API[" + response + "]");
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    };

    return (
        <>
            <Toast ref={toast} />
            <Button style={{ width: "100%" }} icon="pi pi-check" className="p-button p-component p-button-outlined p-button-secondary" type="button" label={labelGeneral} onClick={(e) => overlayPanel.current.toggle(e)} />
            <OverlayPanel showCloseIcon dismissable ref={overlayPanel}>
                <form onSubmit={onSubmit}>
                    <div className="card p-fluid">
                        <h5>{labelGeneral}</h5>
                        <p>{pedido?.flujo?.descripcion}</p>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col-6">
                                <label htmlFor="clasificacion">Clasificación</label>
                                <Dropdown name="clasificacion" value={clasificacionDrop} options={Array.from(clasificacion)} onChange={(e) => setClasificacionDrop(e.value)} placeholder="Seleccionar la clasificación" />
                            </div>
                            <div className="p-field p-col-6">
                                <label htmlFor="tipologia">Tipología</label>
                                <Dropdown name="tipologia" optionValue="id" optionLabel="nombre" value={tipologiaDrop} options={Array.from(datosTipologias)} onChange={(e) => setTipologiaDrop(e.value)} placeholder="Seleccionar Tipologías" />
                            </div>
                            <div className="p-field p-col-6">
                                <label htmlFor="lineaProyectoEstudioTecnico">Estudio Técnico</label>
                                <Dropdown name="lineaProyectoEstudioTecnico" value={lineaProyectoEstudioTecnicoDrop} options={Array.from(lineaProyectoGeneral)} onChange={(e) => setLineaProyectoEstudioTecnicoDrop(e.value)} />
                            </div>
                            <div className="p-field p-col-6">
                                <label htmlFor="lineaProyectoMaterialReferenciado">Material Referenciado</label>
                                <Dropdown name="lineaProyectoMaterialReferenciado" value={lineaProyectoMaterialReferenciadoDrop} options={Array.from(lineaProyectoGeneral)} onChange={(e) => setLineaProyectoMaterialReferenciadoDrop(e.value)} />
                            </div>
                            <div className="p-field">
                                <Button label="Actuar" type="submit"></Button>
                            </div>
                        </div>
                    </div>
                </form>
            </OverlayPanel>
        </>
    );
}
