import moment from "moment";

export function getFechaCorta(fecha) {
    let a = [{ day: "numeric" }, { month: "numeric" }, { year: "numeric" }];
    return join(new Date(fecha), a, "-");
}

export function getFechaCortaConMes(fecha) {
    let a = [{ day: "numeric" }, { month: "short" }, { year: "numeric" }];
    return join(new Date(fecha), a, "-");
}

function join(t, a, s) {
    function format(m) {
        let f = new Intl.DateTimeFormat("es", m);
        return f.format(t);
    }
    return a.map(format).join(s);
}

export function getFechaInicioDia(fecha) {
    let fechaAjustada = getFechaAjusteUTC(fecha);
    fechaAjustada.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    return fechaAjustada;
}

export function getFechaFinDia(fecha) {
    let fechaAjustada = getFechaAjusteUTC(fecha);
    fechaAjustada.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
    return fechaAjustada;
}

export function getFechaAjusteUTC(fecha) {
    let minutosOffset = new Date().getTimezoneOffset();
    let fechaAjustada = moment(fecha).subtract(minutosOffset, "minutes");
    return fechaAjustada;
}
