import React, { useRef } from "react";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { postGeneralApi } from "../api/general";

export function CancelarPedido(props) {
    const { callBack, pedidoId } = props;
    const overlayPanel = useRef(null);
    const toast = useRef();
    const labelGeneral = "Cancelar el Pedido";

    const onSubmit = (e) => {
        e.preventDefault();

        const datosFormularioEnvio = {
            canceladoCantidad: e.target.canceladoCantidad.value,
            canceladoComentario: e.target.canceladoComentario.value,
        };
        if (!datosFormularioEnvio.canceladoCantidad || !datosFormularioEnvio.canceladoComentario) {
            toast.current.show({ severity: "warn", summary: "Datos no válidos", detail: "Deben rellenarse ambos campos" });
            return;
        }
        if (isNaN(datosFormularioEnvio.canceladoCantidad)) {
            toast.current.show({ severity: "warn", summary: "Datos no válidos", detail: "Debe especificar una cantidad numérica válida en la valoración" });
            return;
        }

        postGeneralApi("updateDatosCancelaPedido", datosFormularioEnvio, pedidoId)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    toast.current.show({ severity: "success", summary: "OK", detail: "Datos guardados" });
                    overlayPanel.current.hide();
                    callBack(Date.now());
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error guardando datos" });
                }
            })
            .catch((response) => {
                console.log("Error API[" + response + "]");
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    };

    return (
        <>
            <Toast ref={toast} />
            <Button style={{ width: "100%" }} className="p-button-sm p-button-danger p-button-outlined " type="button" label={labelGeneral} onClick={(e) => overlayPanel.current.toggle(e)} />
            <OverlayPanel showCloseIcon dismissable ref={overlayPanel}>
                <form onSubmit={onSubmit}>
                    <div className="card p-fluid">
                        <h5>{labelGeneral}</h5>
                        <div className="p-field">
                            <label htmlFor="canceladoCantidad">Cantidad valoración</label>
                            <br />
                            <InputText id="canceladoCantidad" name="canceladoCantidad" placeholder="€" />
                        </div>
                        <div className="p-field">
                            <label htmlFor="canceladoComentario">Comentario</label>
                            <br />
                            <InputTextarea cols="40" name="canceladoComentario" rows="4" />
                        </div>
                        <div className="p-field">
                            <Button className="p-button-danger" label="CANCELAR EL PEDIDO" type="submit"></Button>
                        </div>
                    </div>
                </form>
            </OverlayPanel>
        </>
    );
}
