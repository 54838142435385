import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { getGeneralApi } from "../api/general";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { postGeneralApi } from "../api/general";
import { addLocale } from "primereact/api";
import { CALENDAR_LOCALE_ES } from "../utiles/constantes";

export function FlujoActualizador(props) {
    const { pedido, callBack } = props;
    const overlayPanel = useRef(null);
    const toast = useRef();
    const [datosFlujos, setDatosFlujos] = useState({});
    const [flujoDrop, setFlujoDrop] = useState(pedido.flujo.id);
    const labelGeneral = "Cambiar Flujo de Trabajo";

    addLocale("es", CALENDAR_LOCALE_ES);

    async function getListaDatos() {
        await getGeneralApi("listaFlujo")
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setDatosFlujos(respuesta.data.items);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error obteniendo lista de flujos" });
                }
            })
            .catch((response) => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }

    useEffect(() => {
        getListaDatos();
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        const datosFormularioEnvio = {
            flujo: { id: flujoDrop }, //Hack para primeReact Dropdown
        };

        postGeneralApi("updateDatosFlujoPedido", datosFormularioEnvio, pedido.id)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    toast.current.show({ severity: "success", summary: "OK", detail: "Datos guardados" });
                    overlayPanel.current.hide();
                    callBack(Date.now());
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error guardando datos" });
                }
            })
            .catch((response) => {
                console.log("Error API[" + response + "]");
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    };

    return (
        <>
            <Toast ref={toast} />
            <Button style={{ width: "100%" }} className="p-button-sm p-button-outlined" type="button" label={labelGeneral} onClick={(e) => overlayPanel.current.toggle(e)} />
            <OverlayPanel showCloseIcon dismissable ref={overlayPanel}>
                <form onSubmit={onSubmit}>
                    <div className="card p-fluid">
                        <h5>{labelGeneral}</h5>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col-12">
                                <label htmlFor="flujo">Ir al siguiente Flujo de Trabajo:</label>
                                <Dropdown name="flujo" optionValue="id" optionLabel="nombre" value={flujoDrop} options={Array.from(datosFlujos)} onChange={(e) => setFlujoDrop(e.value)} placeholder="Seleccionar Flujo de Trabajo" />
                            </div>
                            <div className="p-field p-col-12">
                                <label htmlFor="boton" style={{ visibility: "hidden" }}>
                                    Actualizar
                                </label>
                                <Button id="boton" label="CAMBIAR" type="submit"></Button>
                            </div>
                        </div>
                    </div>
                </form>
            </OverlayPanel>
        </>
    );
}
