import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import { postGeneralApi } from "../api/general";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import { CALENDAR_LOCALE_ES } from "../utiles/constantes";

export function ProcFechaFabEst(props) {
    const { pedido, callBack } = props;
    const overlayPanel = useRef(null);
    const toast = useRef();
    const [fechaEstimadaCal, setFechaEstimadaCal] = useState(pedido.fechaEstimada);
    const labelGeneral = "Proceso: " + pedido?.flujo?.nombre;

    addLocale("es", CALENDAR_LOCALE_ES);

    const onSubmit = (e) => {
        e.preventDefault();
        const datosFormularioEnvio = {
            fechaEstimada: fechaEstimadaCal,
        };
        postGeneralApi("procFechaFabEst", datosFormularioEnvio, pedido.id)
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    toast.current.show({ severity: "success", summary: "OK", detail: "Envío correcto: " + respuesta?.data?.mensaje });
                    overlayPanel.current.hide();
                    setTimeout(() => {
                        callBack(Date.now());
                    }, 1000);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error en proceso: " + respuesta?.data?.mensaje });
                    overlayPanel.current.hide();
                }
            })
            .catch((response) => {
                console.log("Error API[" + response + "]");
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    };

    return (
        <>
            <Toast ref={toast} />
            <Button style={{ width: "100%" }} icon="pi pi-check" className="p-button p-component p-button-outlined p-button-secondary" type="button" label={labelGeneral} onClick={(e) => overlayPanel.current.toggle(e)} />
            <OverlayPanel showCloseIcon dismissable ref={overlayPanel}>
                <form onSubmit={onSubmit}>
                    <div className="card p-fluid">
                        <h5>{labelGeneral}</h5>
                        <p>{pedido?.flujo?.descripcion}</p>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col-6">
                                <label htmlFor="fechaEstimada">Fecha prevista del pedido</label>
                                <Calendar
                                    showButtonBar={true}
                                    locale="es"
                                    showTime
                                    dateFormat="dd/mm/yy"
                                    hourFormat={24}
                                    id="fechaEstimada"
                                    showIcon
                                    value={new Date(fechaEstimadaCal)}
                                    onChange={(e) => {
                                        setFechaEstimadaCal(e.value);
                                        console.log("FECHA[" + e.value + "]");
                                    }}
                                ></Calendar>
                            </div>
                            <div className="p-field p-col-6">
                                <label htmlFor="actuar" style={{ visibility: "hidden" }}>
                                    Actuar
                                </label>
                                <Button label="Actuar" type="submit"></Button>
                            </div>
                        </div>
                    </div>
                </form>
            </OverlayPanel>
        </>
    );
}
