import React from "react";
import { Button } from "primereact/button";

export function TriestadoTexto(props) {
    const { valor, label, label0, label1, label2, label3, className } = props;

    let txtLabel0 = "NO DEFINIDO";
    let txtLabel1 = "NO";
    let txtLabel2 = "SI";
    let txtLabel3 = "LABEL3";
    if (label0) {
        txtLabel0 = label0;
    }
    if (label1) {
        txtLabel1 = label1;
    }
    if (label2) {
        txtLabel2 = label2;
    }
    if (label3) {
        txtLabel3 = label3;
    }
    let classNameFinal = "p-button-info " + className;
    let icon = "pi pi-circle-off";
    let labelFinal = txtLabel0;
    if (valor === 1) {
        classNameFinal = "p-button-secondary " + className;
        icon = "pi pi-times";
        labelFinal = txtLabel1;
    }
    if (valor === 2) {
        classNameFinal = "p-button-success " + className;
        icon = "pi pi-check";
        labelFinal = txtLabel2;
    }
    if (valor === 3) {
        classNameFinal = "p-button-success " + className;
        icon = "pi pi-check";
        labelFinal = txtLabel3;
    }
    if (label) {
        labelFinal = label + labelFinal;
    }

    return <Button disabled className={classNameFinal} label={labelFinal} icon={icon}></Button>;
}
