//import React from "react";
import { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { getGeneralApi } from "../api/general";
import { Toast } from "primereact/toast";
import Moment from "react-moment";
import { RolTexto } from "../componentes/RolTexto";
export const GestorDatos = (props) => {
    const [datosFormulario, setDatosFormulario] = useState(null);
    const toast = useRef();
    //console.log("Props-logoUrl: " + props.logoUrl);

    const print = () => {
        window.print();
    };

    useEffect(() => {
        getGeneralApi("getDatosPropiosGestor")
            .then((respuesta) => {
                if (respuesta?.data?.success) {
                    setDatosFormulario(respuesta.data.items[0]);
                } else {
                    toast.current.show({ severity: "error", summary: "Error", detail: "Error obteniendo datos" });
                }
            })
            .catch((response) => {
                console.log("Error API[" + response + "]");
                toast.current.show({ severity: "error", summary: "Error", detail: "Error en la petición al servidor" });
            });
    }, []);

    return (
        <div>
            <Toast ref={toast} />
            <Button type="button" label="Imprimir Ficha" icon="pi pi-print" onClick={print} style={{ display: "block", marginBottom: "20px", marginLeft: "6px" }}></Button>

            <div className="p-grid">
                <div className="p-col">
                    <div className="card">
                        <div id="invoice-content">
                            <div className="invoice">
                                <div className="invoice-header">
                                    <div className="invoice-company">
                                        <img id="invoice-logo" className="logo-image" src={props.logoUrl} alt="EuroSegur Logo" />
                                        <div className="company-name">EuroSegur</div>
                                        <div>Parque Empresarial, C/Max Planck, nº 7</div>
                                        <div>03203 Elche (Alicante) Spain</div>
                                    </div>
                                </div>
                                <div className="invoice-to">
                                    <div className="bill-to">Datos Gestor</div>
                                    <div className="invoice-to-info">
                                        <div>{datosFormulario?.nombreCompleto}</div>
                                        <div>Email: {datosFormulario?.email}</div>
                                        <div>Tfno: {datosFormulario?.telefono}</div>
                                        <div>
                                            Desde: <Moment format="DD/MM/YYYY">{datosFormulario?.fecha}</Moment>
                                        </div>
                                        <RolTexto>{datosFormulario?.rol}</RolTexto>
                                    </div>
                                </div>
                                <div className="invoice-to">
                                    <div className="bill-to">Departamento</div>
                                    <div className="invoice-to-info">
                                        <div>{datosFormulario?.dpto?.nombre}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
