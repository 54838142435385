import React from "react";
import { Button } from "primereact/button";
import { useHistory } from "react-router";

export function BotonIdTexto(props) {
    const { children, icono, ruta } = props;
    const history = useHistory();

    return (
        <Button
            type="button"
            className="p-button-secondary"
            onClick={(e) => {
                history.push(ruta + children);
            }}
        >
            <i className={`pi pi-${icono}`}> </i>&nbsp; {children}
        </Button>
    );
}
