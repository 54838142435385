import React from "react";
import { getServerVersionApi } from "../api/autenticacion";

const AppFooter = () => {
    return (
        <div className="layout-footer">
            <div className="footer-logo-container">
                <img id="footer-logo" src="assets/layout/images/logo-dark.svg" alt="diamond-layout" />
                <span className="app-name">EuroSegur PGI</span>
            </div>
            <span className="copyright">
                &#169; Eurosegur PGI ver. C: {process.env.REACT_APP_PGI_CLIENT_VERSION} / S: {getServerVersionApi()}
            </span>
        </div>
    );
};

export default AppFooter;
